import { Link, useParams } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

function AdminLecture() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    depdenecy: false,
    data: {},
  });
  const [refetch, setReFetch] = useState(false);
  const AuthState = useAuthState();
  const { lectureID } = useParams();

  const [successEdit, ErrorEdit, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_EDIT_STUDENT_ALL_SESSION_API,
    "POST",
    submitAdd.flag,
    submitAdd.depdenecy,
    submitAdd.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    const expireAtFormatted = `${data.expire_at_date} ${data.expire_at_time}:00`;

    const finalData = {
      keys: selectedRows.map((item) => item.key),
      expire_at: expireAtFormatted, // formatted as "YYYY-MM-DD HH:MM:SS"
      views: data.views,
    };

    setSubmitAdd({
      flag: "Submit Edit",
      depdenecy: !submitAdd.depdenecy,
      data: finalData,
    });
  };

  useEffect(() => {
    if (successEdit) {
      setReFetch(!refetch);
      reset();
    }
  }, [successEdit]);

  const table = useTable(
    "lectures",
    "",
    "",
    refetch,
    "",
    lectureID,
    "",
    "",
    "",
    "",
    setSelectedRows
  );
  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Lectures | Mr.Ahmed Rahsad"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_addvideos")
            )) && (
            <Link
              to={`/admin/lectures/sessions/${lectureID}/videos`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Videos
            </Link>
          )}
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_addmatrial")
            )) && (
            <Link
              to={`/admin/lectures/sessions/${lectureID}/materials`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Attchments
            </Link>
          )}
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_addHw")
            )) && (
            <Link
              to={`/admin/lectures/sessions/${lectureID}/homework`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Homework
            </Link>
          )}
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_addquiz")
            )) && (
            <Link
              to={`/admin/lectures/sessions/${lectureID}/quiz`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Quiz
            </Link>
          )}
        </div>
        <hr className="w-3/4 border-[1px] border-secondary" />
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_addstudent")
          )) && (
          <div className="bottom-btn flex w-full justify-start asm:justify-center">
            <Link
              to={`/admin/lectures/sessions/${lectureID}/add-student`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Student
            </Link>
          </div>
        )}
      </div>
      {selectedRows.length !== 0 && (
        <div className="py-10 ">
          <div className="sign-in-form flex w-full flex-col items-center ">
            <form
              onSubmit={handleSubmit(onSubmit)}
              method="post"
              className="sm:max-w-3/4 flex w-[450px] max-w-[450px]  flex-col items-start  justify-center rounded-3xl bg-blue-200  p-16 shadow-lg shadow-blue-200/50 sm:w-3/4"
            >
              <>
                {/**
                 * //! Views field
                 */}
                <div className="flex w-full flex-col items-end justify-center gap-2">
                  <label htmlFor="views">Views</label>
                  <input
                    className="signin-inputs max-w-60"
                    type="number"
                    id="views"
                    placeholder="Views"
                    name="views"
                    autoComplete="on"
                    {...register("views", {
                      required: true,
                    })}
                  />
                  {errors.views && (
                    <p className="text-[12px] text-blue-900">
                      {errors.views.type === "required" &&
                        "Please enter your username or email"}
                    </p>
                  )}
                </div>

                {/**
                 * //! Expire At - Date and Time fields
                 */}
                <div className="flex w-full flex-col items-end justify-center gap-2">
                  <label htmlFor="expire_at_date">Expire At - Date</label>
                  <input
                    className="signin-inputs max-w-60"
                    type="date"
                    id="expire_at_date"
                    name="expire_at_date"
                    {...register("expire_at_date", {
                      required: true,
                    })}
                  />
                  {errors.expire_at_date && (
                    <p className="text-[12px] text-blue-900">
                      {errors.expire_at_date.type === "required" &&
                        "Please select a valid date."}
                    </p>
                  )}

                  <label htmlFor="expire_at_time">Expire At - Time</label>
                  <input
                    className="signin-inputs max-w-60"
                    type="time"
                    id="expire_at_time"
                    name="expire_at_time"
                    {...register("expire_at_time", {
                      required: true,
                    })}
                  />
                  {errors.expire_at_time && (
                    <p className="text-[12px] text-blue-900">
                      {errors.expire_at_time.type === "required" &&
                        "Please select a valid time."}
                    </p>
                  )}
                </div>
              </>

              <button
                disabled={!isValid || submitLoading}
                className="submit max-w-60 mt-10"
                type="submit"
              >
                {submitLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Edit"
                )}
              </button>
            </form>
          </div>
        </div>
      )}

      {/**
       * //!---------Table-----
       */}
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_view")
        )) && <div className="h-fit  w-full">{table}</div>}
    </section>
  );
}

export default AdminLecture;
