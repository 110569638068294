//!------edit in table ------

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditCategory({ edit }) {
  const navigate = useNavigate();
  const { categoryID } = useParams();
  const [values, setValues] = useState();
  const [submitCategory, setSubmitCategory] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  let AddEditApi = edit
    ? process.env.REACT_APP_EDIT_CATEGORY_API
    : process.env.REACT_APP_ADD_CATEGORY_API;
  const [addCategorySuccess, addCategoryErrors, submitLoading] = useAxios(
    AddEditApi,
    "POST",
    submitCategory.flag,
    submitCategory.dependency,
    submitCategory.data,
    true
  );
  /* REACT_APP_CATEGORY_EDIT_QUESTIONS_API */
  const [getCategorySuccess, getCategoryErrors, loading] = useAxios(
    `https://api.mrahmedrashad.online/api/admin/questionbank/view/${categoryID}`,
    "GET",
    categoryID,
    categoryID
  );
  useEffect(() => {
    if (getCategorySuccess) {
      let temp = getCategorySuccess.data[0];
      delete temp.img;
      setValues({
        ...temp,
        stage: String(temp.stage),
        section: temp.section,
      });
    }
  }, [getCategorySuccess]);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({ mode: "onTouched", values });

  const watchLevel = watch("stage");
  const onSubmit = (data) => {
    setSubmitCategory({
      flag: "addCategoryRequest",
      dependency: !submitCategory.dependency,
      data: data,
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addCategorySuccess) {
      reset();
      navigate(-1);
    }
  }, [addCategorySuccess]);
  return (
    <section className="flex h-full w-full flex-col items-center dark:bg-dark dark:text-light">
      <HelmetTags title="Categories | Mr Ahmed Rashad"></HelmetTags>
      <div className="form-container my-20 flex flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill out the information to add the category
        </h2>

        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit w-[380px] flex-col  items-center justify-start gap-6 rounded-3xl bg-blue-200 p-10 shadow-lg shadow-red-200/30 md:w-[250px] "
        >
          {/** Category name */}

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate" htmlFor="name">
              Category name
            </label>
            <input
              className="signin-inputs  w-full "
              type="text"
              id="name"
              placeholder="Enter the category name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                maxLength: 100,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="text-[12px] text-blue-900 ">
                {errors.name.type === "required" &&
                  "Please fill out this field"}
                {errors.name.type === "maxLength" &&
                  "The maximum number of characters is 100 characters."}
                {errors.name.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              addCategoryErrors &&
                addCategoryErrors?.response?.data?.errors?.name && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {addCategoryErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>

          {/** stage*/}
          <div
            className={`flex w-full flex-col items-end justify-center  gap-2 `}
          >
            <label className="w-full truncate" htmlFor="stage">
              Class
            </label>
            <select
              name="stage"
              id="stage"
              {...register("stage", { required: true })}
            >
              <option value="11">First preparatory</option>
              <option value="22">Second preparatory</option>
              <option value="33">Third preparatory </option>
              <option value="1">First secondary</option>
              <option value="2">Second secondary</option>
              <option value="3">Third secondary</option>
            </select>

            {errors.stage && (
              <p className="text-[12px] text-blue-900 ">
                {errors.stage.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
            {
              //!-------server errors -----

              addCategoryErrors &&
                addCategoryErrors?.response?.data?.errors?.stage && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {addCategoryErrors?.response?.data?.errors?.stage[0]}
                  </p>
                )
            }
          </div>
          <>
            {+watchLevel === 2 || +watchLevel === 3 ? (
              <>
                <div className="flex w-full items-end gap-20 pb-1 sm:flex-col-reverse">
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="scientific"
                      value="scientific"
                      {...register("section", { required: true })}
                    />
                    <label
                      className="cursor-pointer text-start"
                      htmlFor="scientific"
                    >
                      Scientific
                    </label>
                  </div>

                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="arts"
                      value="arts"
                      {...register("section", { required: true })}
                    />
                    <label className="cursor-pointer text-start" htmlFor="arts">
                      Arts
                    </label>
                  </div>
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="both"
                      value="both"
                      {...register("section", {
                        required: true,
                      })}
                    />
                    <label className="cursor-pointer text-start" htmlFor="both">
                      Both
                    </label>
                  </div>
                </div>
                {errors.section && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.section.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  errors && errors.section && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.section[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  addCategoryErrors?.response?.data?.errors &&
                    addCategoryErrors?.response?.data?.errors?.section && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {addCategoryErrors?.response?.data.errors.section[0]}
                      </p>
                    )
                }
              </>
            ) : (
              ""
            )}
          </>

          {/** submit */}

          <button
            disabled={!isValid || (submitLoading && submitCategory.flag)}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && submitCategory.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : edit ? (
              "Edit classification"
            ) : (
              "Add classification"
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditCategory;
