import { useEffect, useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";

function AdminLectures() {
  const [academiclevel, setAcademiclevel] = useState("11");
  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [academicType, setAcademicType] = useState("arts");
  const [filterdData, setFilteredData] = useState([]);
  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    process.env.REACT_APP_ADMIN_LECTURES_TAB_API,
    "GET",
    "GET",
    reFetch
  );
  useEffect(() => {
    if (allLecturesData?.data) {
      const data =
        +academiclevel === 3 || +academiclevel === 2
          ? allLecturesData?.data
              ?.filter((data) => data.stage === +academiclevel)
              ?.filter((data) => data.section === academicType)
          : allLecturesData?.data?.filter(
              (data) => data?.stage === +academiclevel
            );
      setFilteredData(data);
    }
  }, [academicType, academiclevel, allLecturesData?.data]);
  return (
    <section
      className={`h-full  w-full  px-20 py-20 bg-mainBackground  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Lectures | Mr Ahmed Rashad"></HelmetTags>
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Lectures</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some(
            (permission) => permission.name === "lecture_add"
          )) && (
          <div className="flex w-full gap-10 md:justify-evenly">
            <button
              onClick={() => navigate("/admin/lectures/add-lecture")}
              className="mr-4 rounded-xl border-2 border-secondary order-2 bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add lecture
            </button>
            <button
              onClick={() => navigate("/admin/lectures/add-chapter")}
              className="mr-4 rounded-xl border-2 border-secondary order-1 bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add a chapter
            </button>
          </div>
        )}
        <div className="flex w-full   gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademiclevel("11");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "11" && "bg-secondary text-light"
            }`}
          >
            First preparatory{" "}
          </button>
          <button
            onClick={() => {
              setAcademiclevel("22");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "22" && "bg-secondary text-light"
            }`}
          >
            Second preparatory{" "}
          </button>
          <button
            onClick={() => {
              setAcademiclevel("33");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "33" && "bg-secondary text-light"
            }`}
          >
            Third preparatory{" "}
          </button>
          <button
            onClick={() => {
              setAcademiclevel("1");
            }}
            className={` rounded-xl border-2 border-secondary   px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "1" && "bg-secondary text-light"
            }`}
          >
            First grade
          </button>
          <button
            onClick={() => {
              setAcademiclevel("2");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "2" && "bg-secondary text-light"
            }`}
          >
            Second grade
          </button>
          <button
            onClick={() => {
              setAcademiclevel("3");
            }}
            className={` rounded-xl border-2 border-secondary   px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "3" && "bg-secondary text-light"
            }`}
          >
            Third grade
          </button>
        </div>
        {+academiclevel === 3 || +academiclevel === 2 ? (
          <div className="flex w-full   gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
            <button
              onClick={() => {
                setAcademicType("arts");
              }}
              className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academicType === "arts" && "bg-secondary text-light"
              }`}
            >
              Arts
            </button>
            <button
              onClick={() => {
                setAcademicType("scientific");
              }}
              className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academicType === "scientific" && "bg-secondary text-light"
              }`}
            >
              Scientific
            </button>
          </div>
        ) : null}
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit">
          {filterdData.length === 0 ? (
            <p>No lectures</p>
          ) : (
            filterdData?.map((Card) => {
              if (Card.is_chapter) {
                return (
                  <SingleChapter
                    reFetch={reFetch}
                    setReFetch={setReFetch}
                    admin
                    chapter={Card}
                    key={Card.key}
                  />
                );
              } else {
                return (
                  <SingleCourse
                    reFetch={reFetch}
                    setReFetch={setReFetch}
                    admin
                    lecture={Card}
                    key={Card.key}
                  />
                );
              }
            })
          )}
        </div>
      )}
    </section>
  );
}

export default AdminLectures;
